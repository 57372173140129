import React from 'react';
import qrcode from 'qrcode';
import Upload from './Upload';
import { gzip, AsyncGzip} from 'fflate';
import './SetUploader.css';
import { v4 as uuidv4 } from 'uuid';
const MAGIC_STRING_FILE_TRANSFER_BYTE_LENGTH = 23; // length of magic string in bytes
const MAGIC_STRING_FILE_TRANSFER_START = "DATA_TRANSFER_MESSAGE-S";
const MAGIC_STRING_FILE_TRANSFER_DATA = "DATA_TRANSFER_MESSAGE-D";
const MAGIC_STRING_FILE_TRANSFER_END = "DATA_TRANSFER_MESSAGE-E"; 

class SetUploader extends React.Component {
  state = {
    set: {},
    uploadState: 'none',
    abortController: null,
    progress: {},
    uploadId: null,
    updateTimer: null,
    beginningTime: null,
    stateLink: null,
    qrCode: null,
    setId: null,
    uploadSignature: false,
    xhr: null,
    gzs: null,
    fullQueueSize: 0,
    dataSize: 0,
    chunkSize: 0,
    uuid: null
  }
  paused
  queue = [];
  dataChannel = this.props.p2pDataChannel;
  lastLoadedBytes = 0;
  lastSpeedUpdateTimestamp = 0;
  cancelled = false;

  componentDidUpdate(prevProps){
    if(prevProps.linkConnectionState !== this.props.linkConnectionState || prevProps.p2pDataChannel !== this.props.p2pDataChannel){
      this.cancelled = true;
      this.queue = [];
      this.setState({
        uploadState: 'error',
      });
    }
  }

  componentDidMount() {
    this.setState({
      setId: this.props.id,
      set: this.props.set
    });
    this.cancelled = false;
    
    if (this.props.p2pDataChannel && this.props.p2pDataChannel.readyState === "open") {
      let uuid = uuidv4();
      this.props.onDone({
        uploadLink: "/" + uuid,
        downloadLink: "/" + uuid
      });

      this.setState({
        uploadState: 'importAccept',
        uuid: uuid,
      });
      const listener = (e) => {
        var { data: rawData } = e;

        var data = JSON.parse(rawData);

        if (data.datasetId !== uuid)
        {
          return;
        }

        switch (data.status)
        {
          case "start":
            this.transferFile(this.props.set);
            break;
          case "canceled":
            this.cancelled = true;
            this.queue = [];
            break;
          default:
            console.warn("Unkown data channel message: " );
            break;
        }
      };

      if(this.dataChannel){
        this.dataChannel.addEventListener('message', listener);
      }
    }
    else if (!this.props.p2pConnectionInfo || this.props.p2pConnectionInfo.forceP2PConnection !== true){
      this.startUpload(this.props.set);
    }
  }

  componentWillUnmount() {
    this.abortUpload();
  }
  
  concatenateTwoUint8Array(uint8array1, uint8array2) {
    const uint8array = new Uint8Array(uint8array1.length + uint8array2.length);
    uint8array.set(uint8array1);
    uint8array.set(uint8array2, uint8array1.length)
    return uint8array;
  }

  getNewUploadURL(uploadId, partNumber, s3UploadID) {
    return fetch(`${this.props.downloadHost}/getmultipartuploadurl/${uploadId}/${s3UploadID}/${partNumber}`, {
      method: 'GET'
    })
  }

  getDataChannelMessageBuffer(type, msgObj) {
    let te = new TextEncoder();
    let msgStr
    switch (type){
      case "start" :
        msgStr = JSON.stringify(msgObj)
        return te.encode(MAGIC_STRING_FILE_TRANSFER_START + msgStr).buffer
      case "data" :
        let idObj = {id:msgObj.id};
        msgStr = JSON.stringify(idObj);
        let msgInfo = te.encode(MAGIC_STRING_FILE_TRANSFER_DATA + "l" + msgStr).buffer; // l for length
        var tmp = new Uint8Array(msgInfo.byteLength + msgObj.bytes.byteLength);
        tmp.set(new Uint8Array(msgInfo), 0);
        tmp.set(new Uint8Array(msgObj.bytes), msgInfo.byteLength);
        tmp[MAGIC_STRING_FILE_TRANSFER_BYTE_LENGTH] = msgStr.length; // Replace "l" with string length to read it easily
        return tmp.buffer;
      case "end" :
        msgStr = JSON.stringify(msgObj)
        return te.encode(MAGIC_STRING_FILE_TRANSFER_END + msgStr).buffer
    }
  }

  transferFile(set) {
    if(this.dataChannel.readyState !== "open"){
      console.log("data channel not open", this.dataChannel);
      return;
    }
    
    this.setState({
      uploadState: 'uploading',
    });

    let uint8array
    if ((set.metaData.Data === 'recordxr' || (set.fileData && set.metaData.Format == "zip"))) {
      uint8array = new Uint8Array(set.fileData)
    }
    else {
      uint8array = new Uint8Array(set.rawImageData.buffer, set.rawImageData.byteOffset, set.rawImageData.byteLength) 
    }

    try {
      let msg = this.getDataChannelMessageBuffer(
        "start", 
        {
          id: this.state.uuid,
          totalByte: uint8array.byteLength,
        }
      );

      this.sendP2P(msg);

      const dataInfoSize = JSON.stringify({id:this.state.uuid}).length + 1 + MAGIC_STRING_FILE_TRANSFER_BYTE_LENGTH; // + 1 for JSON byte length
      const incrFactor = (Channel.MAXIMUM_SIZE_DATA_TO_SEND - dataInfoSize);

      this.setState({
        timer: new Date(),
        fullQueueSize: uint8array.length / incrFactor,
        dataSize: uint8array.length,
        chunkSize: incrFactor
      });

      for (let index = 0; index < uint8array.byteLength; index += incrFactor) {

        msg = this.getDataChannelMessageBuffer(
          "data",
          {
            id: this.state.uuid,
            bytes: uint8array.slice(index, index + incrFactor)
          }
        );

        this.sendP2P(msg);
      }

      msg = this.getDataChannelMessageBuffer(
        "end",
        {
          id: this.state.uuid,
        }
      );

      this.sendP2P(msg);

    } catch (error) {
      console.error("error sending big file", error);
    }
  }

  sendP2P(data) {
    this.queue.push(data);
 
    if (this.paused) {
      return;
    }
  
    this.shiftQueue();
  }

  shiftQueue() {
    this.paused = false;
    let message = this.queue.shift();
  
    while (message && !this.cancelled) {
      if (
        this.dataChannel.bufferedAmount &&
        this.dataChannel.bufferedAmount > Channel.BUFFER_THRESHOLD
      ) {
        this.paused = true;
        this.queue.unshift(message);
  
        const listener = () => {
          this.dataChannel.removeEventListener("bufferedamountlow", listener);
          this.shiftQueue();
        };
  
        this.dataChannel.addEventListener("bufferedamountlow", listener);
        return;
      }
  
      try {
        this.dataChannel.send(message);
        message = this.queue.shift();
        var loadedChunks = this.state.fullQueueSize - this.queue.length;
        var loadedBytes = loadedChunks * this.state.chunkSize;
        var now = Date.now();

        var speed = this.state.progress.speed;
        var oldSpeed = this.state.progress.speed ? this.state.progress.speed : 0;
        const speedUpdateThreshold = 800; //ms
        if (now - this.lastSpeedUpdateTimestamp > speedUpdateThreshold)
        {
          speed = (loadedBytes - this.lastLoadedBytes) / ((now - this.lastSpeedUpdateTimestamp)/1000);
          speed = (speed + oldSpeed)/2;

          this.lastLoadedBytes = loadedBytes;
          this.lastSpeedUpdateTimestamp = now;
        }

        this.setState({
          progress: {
            percentage: (1 - (this.queue.length / this.state.fullQueueSize)) * 100,
            transferred: loadedBytes,
            length: this.state.dataSize,
            speed: speed,
            eta: (this.state.dataSize - loadedBytes) / speed
          },
          uploadState: 'uploading',
          timer: now,
          error: null
        });
      } catch (error) {
        throw new Error(
          `Error to send the next data: ${error.name} ${error.message}`
        );
      }
    }

    if (this.queue.length === 0)
    this.setState({
      uploadState: this.cancelled ? 'cancelled' : 'done',
    });
  }

  startUpload(set) {
    let getUploadIdUrl = `${this.props.downloadHost}/getmultipartuploadid`;
  
    if (this.props.user && this.props.user.token) {
      getUploadIdUrl += `?user=${this.props.user.userid}&token=${this.props.user.token}`;
    }
    if ((set.metaData.Data === 'recordxr' || (set.fileData && set.metaData.Format == "zip"))) {
      let getUploadIdUrlWithoutMultiPart;
      if (set.metaData.Data === 'recordxr') {
        getUploadIdUrlWithoutMultiPart = `${this.props.endPointGetUploadId}?file-type=application/vnd.medicalholodeck.recordxr`
      }else if (set.fileData && set.metaData.Format == "zip") {
        getUploadIdUrlWithoutMultiPart = `${this.props.endPointGetUploadId}`;
      }
      fetch(getUploadIdUrlWithoutMultiPart)
      .then(response => response.json())
      .then(response => {
        const uploadData = new Blob([new Uint8Array(set.fileData)]);
        const uploadId = response.id;
        const uploadS3Url = response.s3DataUrl;
        let xhr = new XMLHttpRequest();
        xhr.addEventListener('readystatechange', () =>  {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              try {
                this.props.onDone(this.state);
                fetch(`${this.props.downloadHost}/complete/${uploadId}`, {
                  method: 'POST',
                  body: new URLSearchParams({
                    metadata : JSON.stringify(set.metaData),
                    dataurl : uploadS3Url.substring(0, uploadS3Url.indexOf('?')),
                  }),
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                  }
                  })
              }catch(e) {
                this.props.onError();
                console.warn(e);
              }
  
              this.setState({
                timer: new Date(),
                error: null,
                uploadState: 'done',
                xhr: null
              });
              this.clearUpdateTimer();
            }
          }
        });
  
        xhr.upload.addEventListener('progress', (e) => {
          try {
            if (e.lengthComputable) {
              this.setState({
                progress: {
                  percentage: (e.loaded / e.total) * 100,
                  transferred: e.loaded,
                  length: e.total,
                  speed: (e.loaded - this.state.progress.transferred) / ((new Date() - this.state.timer)/1000),
                  eta: (e.total - e.loaded) / ((e.loaded - this.state.progress.transferred) / ((new Date() - this.state.timer)/1000))
                },
                uploadState: 'uploading',
                timer: new Date(),
                error: null
              })
            }
          }catch(e) {
            console.log(e);
          }
        });
        xhr.open("PUT", uploadS3Url);
        xhr.send(uploadData)
          
        this.setState({
          uploadId,
          uploadLink: uploadS3Url,
          downloadLink: `${this.props.downloadHost}/state/${uploadId}`,
          xhr: xhr
        });
      });
    }else {
      fetch(`${getUploadIdUrl}?gzip=true`)
      .then(response => response.json())
      .then(response => {
        const uploadId = response.id;
        const s3UploadID = response.s3UploadID;

        //Compress upload part
        const uint8data = new Uint8Array(set.rawImageData.buffer);
        let size = uint8data.length;

        let multipartnumber = 1;
        let nextUploadURLPromise = this.getNewUploadURL(uploadId, multipartnumber, s3UploadID);
        const gzs = new AsyncGzip({ level: 1, mem: 11 });
        
        const chunkSize = 1024 * 1024 * 6;
        const minChunkSize = 1024 * 1024 * 5;
        let numberOfIteration = Math.ceil(uint8data.length / chunkSize);
        let allPartsNumberOfChunks = {};
        let bytesUploadingNow = {};
        let chunksUploaded = 0;
        let arrayBuffer = null;
        let sizeOfAllChunksUploaded = 0;
        let chunksNo = 0;
        let firstTime = true;
        const parts = [];
        this.setState({
          beginningTime: new Date()
        })

        //Chunk compression and upload
        gzs.ondata =  async (err, chunk, final) => {
          if (err) {
            console.error(err);
            return;
          }else {

            //Buffer accumulating
            if (arrayBuffer !== null) {
              allPartsNumberOfChunks[chunksNo] +=1;
              arrayBuffer = this.concatenateTwoUint8Array(arrayBuffer, chunk);
            }else {
              arrayBuffer = chunk;
              chunksNo++;
              allPartsNumberOfChunks[chunksNo] = 1;
            }

            //Upload request if buffer is bigger than 5MB
            if (arrayBuffer.length > minChunkSize || final) {
              await nextUploadURLPromise.then((response) => response.json()).then((response) => {
                let uploadS3Url = response.s3DataUrl;

                //First part number setting; - it is not first number, but if first PartNumber is bigger than one it gives information from where to begin counting 
                if (firstTime) {
                  this.setState({
                    progress: {
                      percentage: 0,
                      transferred: 0,
                      length: size,
                      speed: 0,
                      eta: 0
                    },
                    uploadState: 'uploading'
                  });
                  firstTime = false;
                }

                //XMLHttpRequest setting - events and request
                let xhr = new XMLHttpRequest();
                xhr.addEventListener('readystatechange', () =>  {
                  if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                      //Data for progress bar
                      chunksUploaded += Number(allPartsNumberOfChunks[new URLSearchParams(uploadS3Url).get('partNumber')]);
                      bytesUploadingNow[new URLSearchParams(uploadS3Url).get('partNumber')] = 0;

                      if (final) {
                        sizeOfAllChunksUploaded += (allPartsNumberOfChunks[new URLSearchParams(uploadS3Url).get('partNumber')]-1) * chunkSize + (size % chunkSize);
                      }else {
                        sizeOfAllChunksUploaded += allPartsNumberOfChunks[new URLSearchParams(uploadS3Url).get('partNumber')] * chunkSize;
                      }

                      //Setting parts for complete request
                      let etag = xhr.getResponseHeader("ETag");
                      parts.push({
                        ETag: etag.substring(1, etag.length-1),
                        PartNumber: new URLSearchParams(uploadS3Url).get('partNumber')
                      }) 
                      
                      //Last part - completing multi part upload
                      if (chunksUploaded === numberOfIteration) {
                        this.uploadFinished(parts, uploadId, set.metaData, uploadS3Url, s3UploadID);
                      }
                      
                      this.clearUpdateTimer();
                    }
                  }
                }); 

                //XMLHttpRequest event to get data for progress bar
                xhr.upload.addEventListener('progress', (e) => {
                  const ptNumber = new URLSearchParams(uploadS3Url).get('partNumber');
                  //Setting data for progress bar
                    if (final) {
                      bytesUploadingNow[ptNumber] = (e.loaded * (chunkSize * (allPartsNumberOfChunks[ptNumber] - 1) + (size % chunkSize)) / e.total);
                    }else {
                      bytesUploadingNow[ptNumber] = (e.loaded * (chunkSize * allPartsNumberOfChunks[ptNumber]) / e.total);
                    }
                  if (e.lengthComputable) {
                    //Counting bytes that was already uploaded
                    let bytesUploaded = 0;
                    for (let partNumber of Object.keys(bytesUploadingNow)) {
                      if (partNumber === ptNumber) {
                        if (final) {
                          bytesUploaded += (e.loaded * (chunkSize * (allPartsNumberOfChunks[ptNumber] - 1) + (size % chunkSize)) / e.total);
                        }else {
                          bytesUploaded += (e.loaded * (chunkSize * allPartsNumberOfChunks[ptNumber]) / e.total);
                        }
                      }else {
                        bytesUploaded += bytesUploadingNow[partNumber];
                      }
                    }
                    //Setting states for progress bar
                    this.setState({
                      progress: {
                        percentage: ((sizeOfAllChunksUploaded + bytesUploaded) / size) * 100,
                        transferred: sizeOfAllChunksUploaded + bytesUploaded,
                        length: size,
                        speed: (sizeOfAllChunksUploaded + bytesUploaded) / ((new Date() - this.state.beginningTime)/1000),
                        eta: (size - sizeOfAllChunksUploaded - bytesUploaded) / ((sizeOfAllChunksUploaded + bytesUploaded) / ((new Date() - this.state.beginningTime)/1000))
                      },
                      uploadState: 'uploading',
                      timer: new Date(),
                      error: null
                    })
                  }
                });
                xhr.addEventListener('error', (e) => {
                  console.log(e);
                })
                
                xhr.open("PUT", uploadS3Url);
                xhr.send(new Blob([arrayBuffer])); 
                this.setState({
                  uploadId,
                  uploadLink: uploadS3Url,
                  downloadLink: `${this.props.downloadHost}/state/${uploadId}`,
                  xhr: xhr
                });
                arrayBuffer = null;
              
                //Fetch next upload URL
                multipartnumber++;
                if (!final) nextUploadURLPromise = this.getNewUploadURL(uploadId, multipartnumber, s3UploadID);
            }).catch(e => {
              console.warn(e);
            })
            }
          } 
        } 

        //Setting asyncgzip to states
        this.setState({
          gzs: gzs
        })

        //For loop for slicing chunks of data and sending them for compression and upload
        for (let i = 0; i <= numberOfIteration-1; i++) {
          const start = i * chunkSize
          const end = (i + 1) * chunkSize
          let chunk = i < numberOfIteration-1
            ? uint8data.slice(start, end)
            : uint8data.slice(start);
          if (i === numberOfIteration-1) {
            gzs.push(chunk, true);
          }else {
            gzs.push(chunk);
          }
        }
      })
      
    }
  }

uploadFinished(parts, uploadId, metadata, uploadS3Url, s3UploadID) {
  const partsToSend = parts.sort((a,b) => a.PartNumber-b.PartNumber);
  fetch(`${this.props.downloadHost}/completemultipartupload/${uploadId}/${s3UploadID}`, {
    method: "POST",
    body: new URLSearchParams({
      parts: JSON.stringify(partsToSend)
    }),
  })
  .then(() => {
    try {
      this.props.onDone(this.state);
      fetch(`${this.props.downloadHost}/complete/${uploadId}`, {
        method: 'POST',
        body: new URLSearchParams({
          metadata : JSON.stringify(metadata),
          dataurl : uploadS3Url.substring(0, uploadS3Url.indexOf('?')),
        }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
        })
    }catch(e) {
      this.props.onError();
      console.warn(e);
    }
    this.state.gzs.terminate();
    this.setState({
      timer: new Date(),
      error: null,
      uploadState: 'done',
      xhr: null
    });
  })
}
  
  clearUpdateTimer() {
    if (this.state.updateTimer != null) clearTimeout(this.state.updateTimer);
  }

  abortUpload() {
    if (this.state.xhr != null) {
      this.state.xhr.abort();
      if (this.state.uploadState === 'uploading') {
        fetch(`${this.props.downloadHost}/abortmultipartupload/${this.state.uploadId}`, {
          method: 'GET'
        })
      }
    }
    
    if (this.state.updateTimer != null) clearTimeout(this.state.updateTimer);

    if (this.state.gzs != null) {
      this.state.gzs.terminate();
    }
    this.setState({
        set: {},
        uploadState: 'none',
        abortController: null,
        progress: {},
        uploadId: null,
        updateTimer: null,
        beginningTime: null,
        stateLink: null,
        qrCode: null,
        gzs: null,
        setId: null,
        uploadSignature: false,
        xhr: null,
    });
  }

  render() {
    return (
      <div className="setuploader">
        {this.state.setId && <Upload 
          id={this.state.setId}
          uploadState={this.state.uploadState}
          stateLink={this.state.stateLink}
          set={this.state.set}
          qrCode={this.state.qrCode}
          error={this.state.error}
          onAbort={() => this.abortUpload()}
          progress={this.state.progress} />}
      </div>
    );
  }
}

class Channel {
  static MAXIMUM_SIZE_DATA_TO_SEND = 16 * 1024;
  static BUFFER_THRESHOLD = 16 * 1024;
}

export default SetUploader;